/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { AuthContext } from '../Features/Sidebar/AuthContext';

const Main = () => {
    const { user } = useContext(AuthContext);

    const navigate = useNavigate()
    useEffect(()=> {
        if(user){
            navigate('/')
        }
    },[user])


if(user) {



    return(
        <div>

        <Outlet></Outlet>

    </div>
    )
} else {
    return(
        <div>
        <Outlet></Outlet>
     
    </div>
    )
}

//     return (
// <>

       
// </>
//     );
};

export default Main;