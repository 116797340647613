/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Support.css";
import { toast } from "react-hot-toast";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";

import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";

const Support = () => {
  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const notify = () => toast.success("Read");

  function markas() {
    notify();
  }

  return (
    <div className="sup-contact">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar1 />
          </div>
          <div className="main-y">
            <div className="contact-section1">
              <div className="head-pp">
                <table className="table-a1">
                  <caption>Support Form Details</caption>
                  <thead>
                    <tr className="table-a1-tr">
                      <th className="table-a1-th" scope="col">
                        Name
                      </th>
                      <th className="table-a1-th" scope="col">
                        Email
                      </th>
                      <th className="table-a1-th" scope="col">
                        User Id
                      </th>
                      <th className="table-a1-th" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="table-a1-tr">
                      <td className="table-a1-td" data-label="Name">
                        User 1
                      </td>
                      <td className="table-a1-td" data-label="Email">
                        test@gmail.com
                      </td>
                      <td className="table-a1-td" data-label="Contact No">
                        1234567890
                      </td>

                      <td className="table-a1-td" data-label="Action">
                        <div className="btn-b1">
                          <button className="view-bt1" onClick={openModal}>
                            View
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>



<div class="lightbox" id="img1">

	<a href="#" class="close" aria-label="close image">&times;</a>
  <div>

	<img src={require('../../../assests/Img/home2.png')} alt="yummy dessert" loading="lazy"/>
  </div>
</div>


      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="modal-content-h2"
      >
        <div className="close-sv-d">
          <AiOutlineClose onClick={closeModal} size={20} />
        </div>
        <table className="table-a2">
          <caption style={{ padding: "10px 0px" }}>User Details</caption>
          <thead>
            <tr className="table-a2-tr">
              <th className="table-a2-th" scope="col">
                Name
              </th>
              <th className="table-a2-th" scope="col">
                Email
              </th>
              <th className="table-a2-th" scope="col">
                User Id
              </th>
              <th className="table-a2-th" scope="col">
                Message
              </th>
            </tr>
          </thead>
          <tbody>
            <div>
              <tr className="table-a2-tr">
                <td className="table-a2-td" data-label="Name">
                  User 1
                </td>
                <td className="table-a2-td" data-label="Email">
                  test@gmail.com
                </td>
                <td className="table-a2-td" data-label="User Id">
                  1234567890
                </td>
                <td className="table-a2-td" data-label="Message">
                  <span className="contact-span1">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nulla odio similique, nam rem animi itaque corrupti nobis
                    est incidunt veniam aperiam officiis sapiente? Voluptatum
                    itaque eius laborum minus, ratione sequi!
                  </span>

<span className="spa-a1">

<div class="thumbnail-wrapper">
			<a href="#img1" aria-label="Click to enlarge image">
        <div>

							<img src={require('../../../assests/Img/home2.png')} class="thumbnail" alt="yummy dessert"/>
        </div>
  	</a>
		</div>
             






</span>
             </td>

              </tr>
            </div>
          </tbody>
        </table>
        <div className="modal-bb1">
          <button onClick={markas} className="mark-as-btn">
            Mark as Read
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Support;
