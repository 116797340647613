import React, { useEffect, useMemo, useState } from "react";
import "./Forum.css";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { FaUserCircle } from "react-icons/fa";
import { BsFillReplyFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../Firebase/Firebase";

import moment from "moment";

const Forums = () => {
  const navigate = useNavigate();
  const Moment = require("moment");

  const [forum, setForum] = useState();

  useEffect(() => {
    const q = query(collection(db, "Forum"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setForum(
        cities.sort((a, b) => new Moment(b.createdAt) - new Moment(a.createdAt))
      );
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [search, setSearch] = useState("");

  const countries = useMemo(() => {
    if (!search) return forum;

    return forum.filter((item) => {
      return item.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [search, forum]);



  return (
    <div>
      <div className="bg-formus">
        <div className="body-fix-h">
          <div className="wrapper ">
            <div className="flex-sid-forms">
              <div className="sidebar-home-d">
                <Sidebar1 />
              </div>
              <div className="main-y grid-c1">
                <div
                  classNmae="head-d-f"
                  style={{ textAlign: "center", padding: "10px 0px" }}
                >
                  <span className="form-ddd">Forums Details</span>
                </div>
                <div
                style={{textAlign:'center'}}>
                  <span>Total Forums = </span>
                  <span>{forum?.length}</span>
                </div>
                    

                <div className="forum-k">
                  <div className="forums-data1  ">
                    <div className="forums-data ">
                      <form>
                        <input
                          placeholder="Search"
                          type="search"
                          className="search-input-forums"
                          name="search"
                          id="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </form>

                      <div className="border-jh">
                        <div className="sub-sc">
                          <h1 className="all-h1">All Discussions</h1>
                          <div className="btn-ss"></div>
                        </div>
                        <hr />
                        <div className="main-comments-div">
                          {Array.isArray(forum)
                            ? countries.map((item, key) => {
                                return (
                                  <>
                                    <div>
                                      <div
                                        onClick={() =>
                                          navigate(`/ForumReply/${item.id}`, {
                                            state: { user: item },
                                          })
                                        }
                                        className="comment-1"
                                      >
                                        <div className="comm-gf">
                                          <FaUserCircle className="svg-user" />

                                          <div className="updates-time-rep">
                                            <h1 className="h-h1">
                                              {item.name}
                                            </h1>
                                            <div className="span-updates">
                                              <span className="hov-span1">
                                                <span>
                                                  <BsFillReplyFill className="svg-fill" />
                                                </span>
                                                <span>{item.user}</span>
                                              </span>
                                              <span></span>
                                              <span className="hov-span1">
                                                {moment(
                                                  item.createdAt
                                                ).fromNow()}
                                              </span>

                                              <span>
                                                · {item.replies} Replies
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <hr />
                                    </div>
                                  </>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <></>
    </div>
  );
};

export default Forums;
