import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { db, logInWithEmailAndPassword } from "../../Firebase/Firebase";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-hot-toast";
import { getDoc, doc } from "firebase/firestore";
// import axios from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  async function firstClick() {
    getDoc(doc(db, "admin", email.toLowerCase())).then((docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().loginPermission === true) {
          if (docSnap.data().role === "admin") {
            logInWithEmailAndPassword(email, password).then(() => {
              toast.success("Login Successfully.");
              navigate("/");
            });
          } else {
            toast.error("You are not an Admin", {
              position: "top-right",
            });
          }
        }
      } else {
        toast.error("No Account Registered", {
          position: "top-right",
        });
      }
    });
  }

  return (
    <div className="log-bg">
      <div className="log-form-p">
        <div className="logo-pp5">
          <img src={require("../../../assests/Img/logo.png")} alt="" />
        </div>
        <h1 className="log-h1">LOG IN</h1>
        <div className="log-form">
          {/* <form> */}

          <div className="log-fgg">
            <input
              required
              className="input-log"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              name="email"
              id="email"
            />
            <div className="passworld-hide-show">
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input-log"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
              />
              <div
                className="password-icon2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>

            <button
              onClick={() => {
                firstClick();
              }}
              className="log-bb1"
            >
              LOG IN
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
