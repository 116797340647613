import { createBrowserRouter } from "react-router-dom";
import Home from "../../Pages/Home/Home";
import Forum from "../../Pages/Forum/Forum";
import Contactus from "../../Pages/Contactus/Contactus";
import User from "../../Pages/User/User";
import Course from "../../Pages/User/Course";
import Vlog from "../../Pages/Vlog/Vlog";
import Login from "../../Pages/Login/Login";
import Main from "../../layout/Main";
import Modules from "../../Pages/Studydetails/Modules";
import Studydetails from "../../Pages/Studydetails/Studydetails";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Dashboard from "../../Pages/Dashbord/Dashbord";
import Support from "../../Pages/Support/Support";
import Complete from "../../Pages/User/Complete";
import Replydata from "../../Pages/Forum/Replydata";
import Logout from '../../Pages/Logout/Logout'
import Users from "../../../AdminPages/Users";
  
export const router2 = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/Login",
        element: <Login></Login>,
      },
      {
        path: "/logout",
        element: <Logout></Logout>,
      },
  
      
      {
        path: "/",
        element: (
          <PrivateRoute>
            <Users></Users>
          </PrivateRoute>
        ),
      },

    
     
    ],
  },
]);
