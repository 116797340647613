import React, { useContext, useEffect, useState } from "react";

import "./Dashbord.css";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { AuthContext } from "../../Features/Sidebar/AuthContext";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../Firebase/Firebase";
import { toast } from "react-hot-toast";
import { HiUsers } from "react-icons/hi";
import { collection, query } from "firebase/firestore";
import AnimatedText from "react-animated-text-content";
const Dashbord = () => {
  const { user } = useContext(AuthContext);

  const [getUser, setGetUser] = useState();

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "admin", user.email), (doc) => {
      setGetUser({ id: doc.id, ...doc.data() });
    });

    return () => unsub();
  }, []);
  const [user1, setuser] = useState();

  useEffect(() => {
    const q = query(collection(db, "usersLesson"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setuser(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="bg-hh">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="home-side-bar-data">
            <div className="sidebar-home-d">
              <Sidebar1 />
            </div>
            <div className="main-y  ">
              <div className="main-dash">
                <div className="dash-1">
                  <div className="dash-3">
                    <h1 className="dash-head-h1">
                      <AnimatedText
                        type="chars" // animate words or chars
                        animation={{
                          x: "200px",
                          y: "-20px",
                          scale: 1.1,
                          ease: "ease-in-out",
                        }}
                        animationType="float"
                        interval={0.06}
                        duration={0.8}
                        tag="p"
                        className="animated-paragraph"
                        includeWhiteSpaces
                        threshold={0.1}
                        rootMargin="20%"
                      >
                        Dashboard
                      </AnimatedText>
                    </h1>

                    <div className="dash-4">
                      <span className="user-detail-span1">Admin Name </span>

                      <span className="user-detail-span2">{getUser?.name}</span>
                    </div>

                    {/* total users */}
                    <div className="dash1-8">
                      <div className="dash-5">
                        <div className="dash1-7">
                          <div className="dash-6">
                            <span>Users</span>

                            <span>
                              <HiUsers size="20" />
                            </span>
                          </div>
                          <div>
                            <span>{user1?.length}</span>
                          </div>
                        </div>
                      </div>
                      <div className="dash-5">
                        <div className="dash1-7">
                          <div className="dash-6">
                            <span>Otp</span>

                            <span>
                              <HiUsers size="20" />
                            </span>
                          </div>
                          <div>
                            {/* <span>{user1.loginPermission}</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashbord;
