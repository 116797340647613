import firebase from 'firebase/compat/app';
import 'firebase/compat/database'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import {

  getAuth,

  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  getFirestore,

} from "firebase/firestore";
import { getStorage } from "firebase/storage";



const app = firebase.initializeApp({
  apiKey: "AIzaSyCuD4yfvFPNyQImC5wUhmiWu3WeMmUH-Ek",
  authDomain: "tuff-trainer.firebaseapp.com",
  projectId: "tuff-trainer",
  storageBucket: "tuff-trainer.appspot.com",
  messagingSenderId: "557455329590",
  appId: "1:557455329590:web:fa8cd92c40f5182b544b97",
  measurementId: "G-4ZM86J4F3Q"
});
const auth = getAuth(app);
const db = getFirestore(app);
export const storage = getStorage(app);

const logInWithEmailAndPassword = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
 
    }
  };

const logout = () => {
  signOut(auth);
};
export {
  auth,
  db,
  logInWithEmailAndPassword,
  app,
  logout,
};
