import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../Firebase/Firebase";
import "./Vlog.css";
import Vlogdata from "./Vlogdata";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import Modal from "react-modal";
import { toast } from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";

const Vlog = () => {
  const [Vlogs, setVlogs] = useState();

  useEffect(() => {
    const todosref = collection(db, "Blogs");
    const q = query(todosref, orderBy("createdAt", "asc"));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Vlog = [];
      querySnapshot.forEach((doc) => {
        Vlog.push({
          id: doc.id,
          ...doc.data(),
        });
        setVlogs(Vlog);
      });
    });

    return () => unsub();
  }, []);

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const notify = () => toast.success("Add Successfully");

  function Add() {
    notify();
  }

  const [name, setName] = useState("");
  const [file, setFile] = useState();

  return (
    <div>
      <div className=" bg-blog">
        <div
          className="body-fix-h 
   "
        >
          <div className="live-s-f wrapper">
            <div>
              <Sidebar1 />
            </div>
            <div className=" main-y">
              <div className="vlog-c1">
                <div className="live-head-3">
                  <div className="blog-head-h">
                    <h1 className='vlog-hh'>Vlog</h1>
                  </div>
                  <div style={{ textAlign: "center", padding: "10px 0px" }}>
                    <span>Total Vlogs Post = </span>
                    <span>{Vlogs?.length}</span>
                  </div>
                  <div className="vlog-btn1-d">
                    <button className="vlog-btn1" onClick={openModal}>
                      Add Vlog
                    </button>
                  </div>
                  <div className="live-head-1  ">
                    <div className="live-head-2">
                      <div className="blog-data-b">
                        {Vlogs?.map((Vloging) => (
                          <Vlogdata Vlogs={Vloging} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="modal-content-h"
      >
        <div className="header-modal-a1">
          <div>
            <h1 className="home-ah1">Add VLOG</h1>
          </div>
          <div className="svg-close-modal">
            <AiOutlineClose onClick={closeModal} />
          </div>
        </div>
        <div className="form-data-a1">
          {/* <form>
           */}
          <div>
            <input
              type="file"
              className="v-file"
              placeholder="Vlog Url "
              name=""
              id=""
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>
          <div>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input-a2 "
              placeholder="Vlog Name"
              name=""
              id=""
            />
          </div>

          <div className="check-box-post-btn">
            <button onClick={Add} className="add-btn-1">
              Add
            </button>
          </div>
          {/* </form> */}
        </div>
      </Modal>
    </div>
  );
};

export default Vlog;
