
import React from "react";
import "./User.css";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { AiFillCheckCircle } from "react-icons/ai";
import { useLocation  } from "react-router-dom";

const Complete = () => {



  const location = useLocation();

  const user = location.state.user;



  return (
    <div className="bg-user">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar1 />
          </div>
          <div className="main-y">
            <div className="secb1">
              <div className="secb2">
                <div className="com-head">
                  <h1>Module Details</h1>
                </div>
                <div>
                  <div>
                    <h1 className="module-h6">MODULE 1 - INTRODUCTION</h1>
                  </div>

                  <div className="modules-title">
                    <div className="module-a15">
                      <div>
                        <h1 className="modulea-22 {">WHY WE STARTED</h1>
                      </div>
                      <div className="svg-tick1">
                        {user.Module1 >= 25 ? (
<>
<AiFillCheckCircle size={30} />
</>
                        ) : (
                          <>
                          </>
                        )}
                    
                      </div>
                    </div>
                    <div className="module-a15">
                      <div>
                        <h1 className="modulea-22 {">WHAT'S YOUR PURPOSE</h1>
                      </div>
                      <div className="svg-tick1">
                      {user.Module1 >= 50 ? (
<>
<AiFillCheckCircle size={30} />
</>
                        ) : (
                          <>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="module-a15">
                      <div>
                        <h1 className="modulea-22 {">ANALYSING THE PHYSIQUE</h1>
                      </div>
                      <div className="svg-tick1">
                      {user.Module1 >= 75 ? (
<>
<AiFillCheckCircle size={30} />
</>
                        ) : (
                          <>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="module-a15">
                      <div>
                        <h1 className="modulea-22 {">
                          TAKING THE RIGHT STEP (bulk, cut and maintenance )
                        </h1>
                      </div>

                      <div className="svg-tick1">
                      {user.Module1 >= 100 ? (
<>
<AiFillCheckCircle size={30} />
</>
                        ) : (
                          <>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Complete;
