import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore"
import Adminsidebar from "../Components/Features/Sidebar/Adminsidebar";
import UsersTable from "./UsersTable";
import { db } from "../Components/Firebase/Firebase";

const Users = () => {
  const navigate = useNavigate();
  const [user, setuser] = useState([]);
  const [sortCriteria, setSortCriteria] = useState("createdAt,desc"); // Default sorting criteria is 'createdAt'.
  const [search, setSearch] = useState("");

  useEffect(() => {
    const q = query(
      collection(db, "usersLesson"),
      orderBy(sortCriteria.split(",")[0], sortCriteria.split(",")[1]) // Use the selected sorting criteria.
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const users = [];
      querySnapshot.forEach((doc) => {
        users.push({ id: doc.id, ...doc.data() });
      });
      setuser(users);
    });

    return () => {
      unsubscribe();
    };
  }, [sortCriteria]);

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const filteredUsers = user.filter((item) => {
    return item.name.toLowerCase().includes(search.toLowerCase());
  });





  return (
    <div className="bg-user">
      <div className="body-fix-h">
        <div className="wrapper">
          <div className="sidebar-home-d">
            <Adminsidebar />
          </div>
          <div className="main-y">
            <div className="head-use">
              <h1 className="us-h1">
                {/* <AnimatedText
                  type="chars"
                  animation={{
                    x: '200px',
                    y: '-20px',
                    scale: 1.1,
                    ease: 'ease-in-out',
                  }}
                  animationType="float"
                  interval={0.06}
                  duration={0.8}
                  tag="p"
                  className="animated-paragraph"
                  includeWhiteSpaces
                  threshold={0.1}
                  rootMargin="20%"
                >
           
                </AnimatedText> */}
                Users Details
              </h1>
              <div>
                <div className="select-container">
                  <select
                    className="custom-select"
                    value={sortCriteria}
                    onChange={handleSortChange}
                  >
                    <option value="createdAt,desc">Sort by Date</option>
                    <option value="name,asc">Sort by Name</option>
                  </select>
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <span>Total Users = </span>
              <span>{user.length}</span>
            </div>
            <div
              style={{
                padding: "10px 0px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "60%" }}>
                <form>
                  <input
                    placeholder="Search"
                    type="search"
                    className="search-input-forums"
                    name="search"
                    id="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </form>
              </div>
            </div>
            <div class="container">
              <table class="responsive-table">
                <thead>
                  <tr>
                    <th scope="col">User Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Payment Type</th>
                    <th scope="col">Member Ship</th>

                    <th scope="col">Payment Status</th>
                    <th scope="col">Enroll Date</th>
                    {/* <th scope="col">Action</th> */}
                  </tr>
                </thead>
                  {filteredUsers.map((item, key) => (
        
                
                    <UsersTable item={item}/>
              
                  ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
