import React, { useEffect, useState } from "react";

import { RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./App.css";
import "./Style.scss";

import { doc, onSnapshot } from "firebase/firestore";
import { useContext } from "react";
import { db } from "./Components/Firebase/Firebase";
import { AuthContext } from "./Components/Features/Sidebar/AuthContext";

import { router } from "./Components/Routes/Route/Route";
import { router2 } from "./Components/Routes/Route/Route2";
const App = () => {
  const { user } = useContext(AuthContext);

  const [getUser, setGetUser] = useState();

  useEffect(() => {
    try {
      const unsub = onSnapshot(doc(db, "admin", user?.email), (doc) => {
        setGetUser({ id: doc.id, ...doc.data() });
      });

      return () => unsub();
    } catch {}
  }, [getUser, user]);


  return (
    <>
      <Toaster />

      {getUser?.user === "admin" ? (
        <RouterProvider router={router2}></RouterProvider>
        ) : (
          <>
              <RouterProvider router={router}></RouterProvider>
        </>
      )}

    </>
  );
};

export default App;
