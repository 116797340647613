
import React from "react";
import "./User.css";

import Sidebar1 from "../../Features/Sidebar/Sidebar1";

import { useLocation, useNavigate, useParams } from "react-router-dom";

const Course = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const user = location.state.user;

  return (
    <div className="bg-user">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar1 />
          </div>
          <div className="main-y">
            <div className="head-use">
              <h1 className="us-h1">User Course</h1>
            </div>
            <div>
              <div className="card-head-course">
                <div className="card-head-data-j">
                  {/* <div>
                    <img
                      className="ww"
                      src={require("../../../assests/Img/home2.png")}
                      alt=""
                    />
                  </div> */}
                  {/* <div className="card-h-certifi">
                    <h1>Contest Prep Certification</h1>
                  </div>
                  <div>
                    <button
                      onClick={() => navigate(`/User/${id}/Modules` , {
                        state: { user: user },
                      })}
                      className="status-btn-1"
                    >
                      View Staus
                    </button>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="contact-section1">
              <div className="head-pp">
                <table className="table-a1">
                  <caption>User Details</caption>
                  <thead>
                    <tr className="table-a1-tr">
                      <th className="table-a1-th" scope="col">
                        Name
                      </th>
                      <th className="table-a1-th" scope="col">
                        Email
                      </th>
                      <th className="table-a1-th" scope="col">
                        Contact No
                      </th>
                      <th className="table-a1-th" scope="col">
                        Registration Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="table-a1-tr">
                      <td className="table-a1-td" data-label="Name">
                        {user.name}
                      </td>
                      <td className="table-a1-td" data-label="Email">
                        {user.email}
                      </td>
                      <td className="table-a1-td" data-label="Contact No">
                        1234567890
                      </td>
                      <td className="table-a1-td" data-label="Registration Date">
                        {/* <span>{user.createdAt?.toDate().toDateString()}</span> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
