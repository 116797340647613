import React from "react";
import ReactPlayer from "react-player";
import Modal from "react-modal";
import { toast } from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
// import './Streadata.css'
const Vlogdata = ({ Vlogs }) => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const notify = () => toast.success("Change Successfully");

  function Change() {
    notify();
  }

  const notify1 = () => toast.success("Delete Successfully");

  function Delete() {
    notify1();
  }

  return (
    <div className="stream-comtainer">
      <div class="blog-card-v">
        <div className="blog-a3">
          <ReactPlayer
            className="i-f-dash-1 video-i-1"
            width="560"
            height="315"
            url={Vlogs?.url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            controls
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
              },
            }}
          />
        </div>
        <div class="card__content">
          <time datetime="2021-03-30" class="card__date">
            <p>{Vlogs?.createdAt.toDate().toLocaleDateString()}</p>
          </time>
          <span class="card__title">{Vlogs?.name}</span>

          <div className="del-vv1">
            <button className="vlog-edit1" onClick={openModal}>
              Edit
            </button>
            <button onClick={Delete} className="vlog-delete1">
              Delete
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="modal-content-h"
      >
        <div className="header-modal-a1">
          <div>
            <h1 className="home-ah1">Edit Vlog</h1>
          </div>
          <div className="svg-close-modal">
            <AiOutlineClose onClick={closeModal} />
          </div>
        </div>
        <div className="form-data-a1">
          {/* <form>
           */}
          <div>
            <input
              type="text"
              className="input-a2 "
              placeholder="Vlog Url"
              name=""
              id=""
            />
          </div>
          <div>
            <input
              type="text"
              className="input-a2 "
              placeholder="Vlog Name"
              name=""
              id=""
            />
          </div>

          <div className="check-box-post-btn">
            <button onClick={Change} className="add-btn-1">
              Change
            </button>
          </div>
          {/* </form> */}
        </div>
      </Modal>
    </div>
  );
};

export default Vlogdata;
