import { collection, onSnapshot, query,deleteDoc,  doc, orderBy} from "firebase/firestore";
import React, { useEffect, useState } from "react";

import "./Contactus.css";
import { toast } from "react-hot-toast";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";

import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { db } from "../../Firebase/Firebase";

const Contact = () => {
  const [contact, setcontact] = useState();

  useEffect(() => {
    const q = query(collection(db, "ContactUs") , orderBy('createdAt' , 'desc'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setcontact(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [pro, setpro] = useState();

  function openModal(item) {
    setIsOpen(true);
    setpro(item);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const notify = () => toast.success("Read");

  function markas() {
    notify();
  }


  async function del(id) {
    await deleteDoc(doc(db, "ContactUs", id)).then(()=>{
      toast.success("delete")
    })
    
    ;
  }


  return (
    <div className="bg-contact">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar1 />
          </div>
          <div className="main-y">
            <div className="contact-section1">
              <div className="head-pp">
                <h1 className="contact-d-h">

                Contact Form Details
                </h1>
                  <div
                  style={{  textAlign: "center",padding:"10px 0px"}}
                  >
                 
                    <span>Total Queries = </span>
                   <span>
         {contact?.length}
                   </span>
                
                  </div>
                <table className="table-a1">
                  {/* <caption>Contact Form Details</caption> */}
               
                  <thead>
                    <tr className="table-a1-tr">
                      <th className="table-a1-th" scope="col">
                        Name
                      </th>
                      <th className="table-a1-th" scope="col">
                        Email
                      </th>
                      <th className="table-a1-th" scope="col">
                        Contact No
                      </th>
                      <th className="table-a1-th" scope="col">
                        Form Fill Date
                      </th>
                      <th className="table-a1-th" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>




                  {Array.isArray(contact)
                    ? contact.map((item, key) => {
                        return (
                          <>
                            <tbody>
                              <tr className="table-a1-tr">
                                <td className="table-a1-td" data-label="Name">
                                  {item.name}
                                </td>
                                <td className="table-a1-td" data-label="Email">
                                  {item.email}
                                </td>
                                <td
                                  className="table-a1-td"
                                  data-label="Contact No"
                                >
                                  {item.phone}
                                </td>
                                <td
                                  className="table-a1-td"
                                  data-label="Form Fill Date"
                                >
                                  <span>
                                    {item?.createdAt
                                      .toDate()
                                      .toLocaleDateString()}
                                  </span>
                                </td>
                                <td className="table-a1-td" data-label="Action">
                                  <div className="btn-b1"
                                  style={{display:'flex',
                                    justifyContent: "center",
                                    gap:'10px'
                                }}
                                  >
                                    <button
                                      className="view-bt1"
                                      onClick={() => openModal(item)}
                                    >
                                      View
                                    </button>
                                    <button
                                      className="view-bt1"
                                      onClick={() => del(item.id)}

                                    >
                                     Delete
                                    </button>
                                    
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        );
                      })
                    : null}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="modal-content-h2"
      >
        <div className="close-sv-d">
          <AiOutlineClose onClick={closeModal} size={20} />
        </div>
        <table className="table-a2">
          <caption style={{ padding: "10px 0px" }}>User Details</caption>
          <thead>
            <tr className="table-a2-tr">
              <th className="table-a2-th" scope="col">
                Name
              </th>
              <th className="table-a2-th" scope="col">
                Email
              </th>
              <th className="table-a2-th" scope="col">
                Contact No
              </th>
              <th className="table-a2-th" scope="col">
                Message
              </th>
            </tr>
          </thead>
          <tbody>
            <div>
              <tr className="table-a2-tr">
                <td className="table-a2-td" data-label="Name">
                  {pro?.name}
                </td>
                <td className="table-a2-td" data-label="Email">
                  {pro?.email}
                </td>
                <td className="table-a2-td" data-label="Contact No">
                  {pro?.phone}
                </td>
                <td className="table-a2-td" data-label="Message">
                  <span className="contact-span1">{pro?.message}</span>
                </td>
              </tr>
            </div>
          </tbody>
        </table>
        <div className="modal-bb1">
          <button onClick={markas} className="mark-as-btn">
            Mark as Read
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Contact;
