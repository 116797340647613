import React, { useContext, useState } from "react";
import {
  SDivider,
  SLink,
  SLinkContainer,
  SLinkIcon,
  SLinkLabel,
  SLinkNotification,
  SLogo,
  SSidebar,
  SSidebarButton,
} from "./styles";
import "./Side.css";

import {
  BsCameraVideoFill,
  BsChatSquareText,
  BsLayoutSplit,
} from "react-icons/bs";
import { ImBooks } from "react-icons/im";
import { FaBookmark } from "react-icons/fa";
import { CiStreamOn } from "react-icons/ci";
import { TbListDetails } from "react-icons/tb";

import { MdLogout } from "react-icons/md";

import { useLocation } from "react-router-dom";
import { IoMdContacts } from "react-icons/io";
import { AuthContext } from "./AuthContext";
import { toast } from "react-hot-toast";
import {AiFillHome}from "react-icons/ai";
import {HiUsers}from "react-icons/hi";
import {BiSupport}from "react-icons/bi";

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();

  const { userLogout } = useContext(AuthContext);

  const handleLogout = () => {
    userLogout()
      .then((result) => {
        toast.success("Successfully Logout!");
      })
      .catch((error) => console.error(error));
  };

  return (
    <SSidebar isOpen={sidebarOpen}>
      <>
        <SSidebarButton
          isOpen={sidebarOpen}
          onClick={() => setSidebarOpen((p) => !p)}
        >
          <BsLayoutSplit />
        </SSidebarButton>
      </>
      <SLogo>{/* <img src={logoSVG} alt="logo" /> */}</SLogo>

      {linksArray.map(({ icon, label, notification, to }) => (
        <div class={"tooltip"}>
          <SLinkContainer key={label} isActive={pathname === to}>
            <SLink to={to} style={!sidebarOpen ? { width: `fit-content` } : {}}>
              <SLinkIcon isActive={pathname === to}>{icon}</SLinkIcon>
              {sidebarOpen && (
                <>
                  <SLinkLabel isActive={pathname === to}>{label}</SLinkLabel>
                  {/* if notifications are at 0 or null, do not display */}
                  {!!notification && (
                    <SLinkNotification>{notification}</SLinkNotification>
                  )}
                </>
              )}
            </SLink>
          </SLinkContainer>
          {/* <SLinkIcon isActive={pathname === to}>{icon}</SLinkIcon>  */}

          {sidebarOpen ? (
            ""
          ) : (
            <div class={"right"}>
              <div class="text-content">
                {sidebarOpen ? (
                  ""
                ) : (
                  <h3 style={{ whiteSpace: "pre" }}>
                    <SLinkLabel isActive={pathname === to}>{label}</SLinkLabel>
                  </h3>
                )}
              </div>
              <i></i>
            </div>
          )}
        </div>
      ))}

      <SDivider />
      {secondaryLinksArray.map(({ icon, label,to }) => (
        <div class={"tooltip"}>
          <SLinkContainer key={label}>
            <SLink
        to={to}
             onClick={() => handleLogout()}
              style={!sidebarOpen ? { width: `fit-content` } : {}}
            >
            <SLinkIcon>{icon}</SLinkIcon>
            {sidebarOpen && <SLinkLabel>{label}</SLinkLabel>}
            {sidebarOpen ? (
              ""
            ) : (
              <div class="right">
                <div class="text-content">
                  {sidebarOpen ? (
                    ""
                  ) : (
                    <h3 style={{ whiteSpace: "pre" }}>{label}</h3>
                  )}
                </div>
                <i></i>
              </div>
            )}

            </SLink>
          </SLinkContainer>
        </div>
      ))}

      <SDivider />
    </SSidebar>
  );
};

const linksArray = [
  {
    label: "HOME",
    icon: <AiFillHome/>,
    to: "/",
    notification: 0,
  },
  {
    label: "USER",
    icon: <HiUsers />,
    to: "/User",
    notification: 0,
  },

  {
    label: "STUDY DETAILS",
    icon: <ImBooks />,
    to: "/Course",
    notification: 0,
  },
  {
    label: "FORUMS",
    icon: <BsChatSquareText />,
    to: "/Forums",
    notification: 0,
  },

  {
    label: "VLOG",
    icon: <BsCameraVideoFill />,
    to: "/Vlog",
    notification: 0,
  },

  {
    label: "CONTACT US",
    icon: <IoMdContacts />,
    to: "/Contactus",
    notification: 0,
  },
  {
    label: "SUPPORT",
    icon: <BiSupport />,
    to: "/Support",
    notification: 0,
  },
];
const secondaryLinksArray = [
  {
    label: "LOGOUT",
    icon: <MdLogout />,
    to: "/Login",
  },
];

export default Sidebar;
